<template>
    <transition name="slide">
        <div v-if="project" class="project-wrapper">
            <div class="project-header">
                <div class="copy">
                    <router-link to="/"><span class="material-icons">arrow_left</span>Miles Gilbert</router-link>
                    <h1>{{project.title}}</h1>
                    <h1 class="text-serif text-light">{{project.shortDescription}}</h1>
                </div>
            </div>
            <div v-html="project.description" class="project-description"></div>
            <ul class="info text-light">
                <li><strong>Role: </strong>{{project.role}}</li>
                <li><strong>Client: </strong>{{project.client}}</li>
                <li><strong>Year: </strong>{{project.year}}</li>
            </ul>
            <div class="project-images" :class="{grid: project.layout === 'grid'}">
                <div class="asset" :class="{inset: asset.inset}" v-for="asset in project.images.showcase" :key="asset">
                    <img v-if="asset.type === 'image'"
                    :src="GetAssetPath(asset.path, project.path)">
                    <video v-else-if="asset.type === 'video'"
                    preload="auto" autoplay loop muted>
                        <source :src="GetAssetPath(asset.path, project.path)" type="video/mp4; codecs=avc1.42E01E,mp4a.40.2">
                    </video>
                    <div v-if="asset.type === 'vimeo'" class="vimeo">
                        <iframe
                        :src="'https://player.vimeo.com/video/' + asset.path + '?h=dc254897e2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        title="">
                        </iframe>
                    </div>
                    <p v-if="asset.caption"
                    class="caption text-light">{{asset.caption}}</p>
                </div>
            </div>
            <Colophon @load-project="LoadProject($event)" :currentProject="project"/>
        </div>
    </transition>
</template>

<script>
import Colophon from '@/components/Colophon'
export default {
    name: 'View',
    components: {
        Colophon
    },
    data () {
        return {
            project: null,

        }
    },
    methods: {
        GetAssetPath(path, dir) {
            if (dir === 'thumb') {
                return require('@/assets/thumbs/' + path)
            } else {
                return require('@/assets/project/' + dir + '/' + path)
            }
            
        },
        LoadProject(path) {
            var projectTotal = Object.keys(this.$store.state.projects).length
            for(var i = 0; i <projectTotal; i++) {
                if (Object.keys(this.$store.state.projects)[i] === path) {
                    this.project = this.$store.state.projects[path]
                }
            }
        }
    },
    mounted() {
        this.LoadProject(this.$route.query.project)
    },
    beforeUpdate() {
        this.LoadProject(this.$route.query.project)
    }
}
</script>

<style>
.project-wrapper {
    grid-area: 1/1/3/1;
    margin-top: 30px;
}
.project-header .copy, .project-description p {
    padding: 0 20px;
    margin: 30px 0;
    max-width: 600px;
}
.project-header img {
    width: 100%;
    margin: 60px 0;
}
.project-header a {
    font-family: 'IBM Plex Sans';
    display: flex;
    align-items: center;
    margin-left: -6px;
}
.info {
    font-size: 16px;
    margin: 20px 0;
    padding: 30px 20px;
    border-radius: 4px;
    font-family: 'IBM Plex Sans';
}
.copy h1 {
    line-height: 34px;
}
.project-images.grid {
    display: grid;
    grid-gap: 14px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.project-images .asset {
    margin: 60px 0;
}
.asset .caption {
    font-family: 'IBM Plex Sans';
    font-size: 16px;
    line-height: normal;
    padding: 0 20px;
    max-width: 375px;
}
.project-images img, .project-images video {
    width: 100%;
}
.vimeo {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
}
.vimeo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.asset.inset {
    display: flex;
    background-color: #fff;
    position: relative;
}
.asset.inset p {
    position: absolute;
    bottom: 0;
}
.asset.inset video {
    margin: 40px auto;
    border-radius: 4px;
    width: initial;
}
@media screen and (min-width: 900px) {
    .project-header img {
        border-radius: 4px;
    }
    .project-header .info {
        max-width: 600px;
        display: flex;
        justify-content: space-between;
    }
}
</style>